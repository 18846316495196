import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 
    "passwordInput", 
    "newPasswordInput", 
    "confirmPasswordInput", 
    "matchCheck", 
    "lengthCheck", 
    "lengthCheckTrueIndicator", 
    "lengthCheckFalseIndicator",
    "matchCheckTrueIndicator",
    "matchCheckFalseIndicator"
  ];

  togglePasswordVisibility() {
    this.passwordInputTargets.forEach((passwordInput) => {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    });
  }

  checkPasswordLength() {
    if (this.newPasswordInputTarget.value.length >= 12) {
      this.lengthCheckTarget.classList.add("text-green-500");
      this.lengthCheckTrueIndicatorTarget.classList.remove("hidden");
      this.lengthCheckFalseIndicatorTarget.classList.add("hidden");
    } else {
      this.lengthCheckTarget.classList.remove("text-green-500");
      this.lengthCheckTrueIndicatorTarget.classList.add("hidden");
      this.lengthCheckFalseIndicatorTarget.classList.remove("hidden");
    }
  }

  checkPasswordsMatch() {
    console.log("Checking passwords match");
    if (this.newPasswordInputTarget.value === this.confirmPasswordInputTarget.value) {
      this.matchCheckTarget.classList.add("text-green-500");
      this.matchCheckTrueIndicatorTarget.classList.remove("hidden");
      this.matchCheckFalseIndicatorTarget.classList.add("hidden");
    } else {
      this.matchCheckTarget.classList.remove("text-green-500");
      this.matchCheckTrueIndicatorTarget.classList.add("hidden");
      this.matchCheckFalseIndicatorTarget.classList.remove("hidden");
    }
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "form", "stage1", "stage2", "stage3", "emailSelect", "addressSelect", "telephoneSelect", "loyaltyInput", "nextButton", "retailerNameInput" ]

  nextStage(event) {
    let currentStageIndex = event.params.currentStage
    if (currentStageIndex === 1) {
      if (this.canAdvanceToStage2()) {
        this.moveToStage(currentStageIndex, currentStageIndex + 1);
      }
    } else if (currentStageIndex === 2) {
      if (this.canAdvanceToStage3()) {
        this.moveToStage(currentStageIndex, currentStageIndex + 1);
      }
    }
  }

  previousStage(event) {
    let currentStageIndex = event.params.currentStage
    if (currentStageIndex === 2) {
      this.moveToStage(currentStageIndex, currentStageIndex - 1);
    } else if (currentStageIndex === 3) {
      this.moveToStage(currentStageIndex, currentStageIndex - 1);
    }
  }

  canAdvanceToStage2() {
    if (this.hasRetailerNameInputTarget && this.retailerNameInputTarget.value === "") {
      alert("Please enter a retailer name before proceeding.");
      return;
    }
    if (!this.emailSelectTarget.value) {
      alert("Please enter an email before proceeding.");
      return
    };
    return true;
  }
  
  canAdvanceToStage3() {
    if (this.addressSelectTarget.value || this.telephoneSelectTarget.value || this.loyaltyInputTarget.value) {
      return true;
    } else {
      alert("Please enter at least one of the three additional pieces of information before proceeding.");
      return false;
    }
  }
  
  moveToStage(currentStageIndex, nextStageIndex) {
    let currentStage = this.stageFromIndex(currentStageIndex);
    let nextStage = this.stageFromIndex(nextStageIndex);
    currentStage.classList.add("hidden");
    nextStage.classList.remove("hidden");
  }

  stageFromIndex(index) {
    return [this.stage1Target, this.stage2Target, this.stage3Target][index - 1];
  }
}
// navbar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mobileMenu", "userDropdown"]

  connect() {
    this.handleResize = this.handleResize.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('click', this.handleClickOutside)
    this.handleResize()
  }

  disconnect() {
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleResize() {
    if (window.innerWidth >= 768) { // md breakpoint
      this.mobileMenuTarget.classList.add('translate-x-full')
    }
  }

  toggleMobileMenu() {
    this.mobileMenuTarget.classList.toggle("translate-x-full")
  }

  closeMobileMenu() {
    this.mobileMenuTarget.classList.add("translate-x-full")
  }

  toggleUserDropdown(event) {
    event.stopPropagation()
    this.userDropdownTarget.classList.toggle("hidden")
  }

  handleClickOutside(event) {
    // if (!this.userDropdownTarget.contains(event.target) && !event.target.matches('#user-menu-button')) {
    //   this.userDropdownTarget.classList.add("hidden")
    // }
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "select", "newField", "addNew", "useSaved", "errors" ]

  connect() {
    document.body.addEventListener('resourceAddedSuccessfully', this.handleSuccessfulSubmission.bind(this));
  }

  toggleNewField() {
    this.selectTarget.classList.add("hidden")
    this.newFieldTarget.classList.remove("hidden")
    this.addNewTarget.classList.add("hidden")
    this.useSavedTarget.classList.remove("hidden")
    this.errorsTarget.innerHTML = ""  // Clear any existing errors
  }

  toggleSavedField() {
    this.selectTarget.classList.remove("hidden")
    this.newFieldTarget.classList.add("hidden")
    this.addNewTarget.classList.remove("hidden")
    this.useSavedTarget.classList.add("hidden")
    this.errorsTarget.innerHTML = ""  // Clear any existing errors
  }

  handleSuccessfulSubmission(event) {
    this.toggleSavedField()
  }
}
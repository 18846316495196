import { Controller } from "@hotwired/stimulus";

export default class TermsAndConditionsController extends Controller {
  static targets = ["privacyPolicy", "termsAndConditions"];

  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  renderPrivacyPolicy() {
    this.showPrivacyPolicy();
    this.hideTermsAndConditions();
  }

  renderTermsAndConditions() {
    this.showTermsAndConditions();
    this.hidePrivacyPolicy();
  }

  hidePrivacyPolicy() {
    this.privacyPolicyTarget.classList.add("hidden");
  }

  hideTermsAndConditions() {
    this.termsAndConditionsTarget.classList.add("hidden");
  }

  showTermsAndConditions() {
    this.termsAndConditionsTarget.classList.remove("hidden");
  }

  showPrivacyPolicy() {
    this.privacyPolicyTarget.classList.remove("hidden");
  }
}
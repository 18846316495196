import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = [ "canvas", "dataUrlStore", "savedSignature"]
  static values = { savedDataUrl: String }


  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget)
    this.signaturePad.addEventListener("endStroke", () => this.saveSignature());
  }

  async useSavedSignature() {
    const dataURL = this.savedDataUrlValue;
    await this.signaturePad.fromDataURL(dataURL);
    this.saveSignature();
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  saveSignature() {
    if (!this.signaturePad.isEmpty()) {
      const dataURL = this.signaturePad.toDataURL();
      this.dataUrlStoreTarget.value = dataURL;
    }
  }
}
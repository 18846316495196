// navbar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["newAddressForm"]

  connect() {
    this.newAddressFormTarget.addEventListener('addressAdded', this.removeForm.bind(this))
  }

  removeForm() {
    this.newAddressFormTarget.innerHTML = ""  
  }

  disconnect() {
    this.newAddressFormTarget.removeEventListener('addressAdded', this.addNewAddress.bind(this))
  }
}
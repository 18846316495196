import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "feedback", "fileUploadInput"]

  uploadFile(event) {
    this.feedbackTarget.textContent = "Uploading..."
    // Trigger the form submission
    this.formTarget.requestSubmit()
    this.fileUploadInputTarget.value = "";
    this.formTarget.reset();
    this.feedbackTarget.textContent = ""
  }
}
import { Controller } from "@hotwired/stimulus";

export default class NewRetailerController extends Controller {
  static targets = ["emailInput"];
  
  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  showEmailInput() {
    this.emailInputTarget.classList.remove("hidden");
  }

  hideEmailInput() {
    this.emailInputTarget.classList.add("hidden");
  }
}